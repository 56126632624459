import React, { useState } from 'react';
import { useInView } from 'react-intersection-observer';
import Container from './Container';

export default function Intro() {
    const [fadeIn, setFadeIn] = useState(false);

    const { ref, inView } = useInView({
        triggerOnce: true,
        threshold: 0.1,
    });

    if (inView && !fadeIn) {
        setFadeIn(true);
    }

    return (
        <div className={`bg-img ${fadeIn ? 'fade-in' : 'opacity-0'}`} ref={ref} id='intro'>
            <div className="bg-black bg-opacity-70">
                <Container>
                    <div className="grid grid-cols-2 items-center gap-10 py-32 lg:py-40">
                        <div className="text-wrap col-span-2 text-center flex justify-center">
                            <div className="wrap w-full lg:w-[60%]">
                                <h1 className='text-4xl lg:text-8xl font-extrabold mb-5 text-white font-candy'>$LADY <span className="text-pink mb-16">TOKEN</span></h1>
                                {/* <span className=' text-lg lg:text-xl lg:leading-9 text-gray-300 font-pig'>From VK's <span className='font-candy'>2006</span> Mascot Competition to Telegram's Iconic Duo: Lady and Man's Journey from Vault Boy Inspiration to Modern Media Marvels, Reimagined for a New Era</span> */}
                                <div className="btn-wrap flex gap-4 mt-5 font-pig">
                                    <a href="https://dedust.io/swap/TON/EQASaIVDyf8QBfhiLUOeiu2ayVoPZYadaPQTUAO1uED6TvAA" className='w-full'>
                                        <button className=" rounded-full p-4 w-full bg-black text-white text-center text-sm font-bold">Buy Now</button>
                                    </a>
                                    <a href="https://dexscreener.com/ton/eqchkmsdbch-2vvy1hyqlfs1z_oojisr495b2m9oopllnfvb" className='w-full'>
                                        <button className=" rounded-full p-4 w-full bg-pink text-white text-center text-sm font-bold">Chart</button>
                                    </a>
                                </div>
                            </div>
                        </div>

                        {/* <div className="img-wrap">
                            <img src="/asset/ladypng.png" alt="Lady" className='w-px] mx-auto' />
                        </div> */}
                    </div>
                </Container>
            </div>
        </div>
    );
}
