import React from 'react';
import { useInView } from 'react-intersection-observer';
import Container from './Container';

export default function Htb() {
    const steps = [
        {
            title: "Create a Wallet",
            text: "Download Tonkeeper wallet extension.",
            icon: "icon_placeholder_1"
        },
        {
            title: "Get Some TON",
            text: "To buy $LADY, first fund your TON wallet. You can buy TON on Bybit, OKX or other exchanges.",
            icon: "icon_placeholder_2"
        },
        {
            title: "Go to Dedust",
            text: "Visit dedust.io, connect your wallet, and paste the $LADY token address. Select $LADY, confirm, and sign when prompted by Tonkeeper.",
            icon: "icon_placeholder_3"
        },
        {
            title: "Swap TON to $LADY",
            text: "Swap TON for $LADY with zero taxes. Slippage settings may be required during market volatility.",
            icon: "icon_placeholder_4"
        }
    ];

    const { ref: headerRef, inView: headerInView } = useInView({
        triggerOnce: true,
        threshold: 0.1,
    });

    const { ref: leftGridRef, inView: leftGridInView } = useInView({
        triggerOnce: true,
        threshold: 0.1,
    });

    const { ref: rightGridRef, inView: rightGridInView } = useInView({
        triggerOnce: true,
        threshold: 0.1,
    });

    return (
        <div className="wrap bg-black text-white py-32 font-pig">
            <Container>
                <div className={`header font-candy mb-16 text-5xl text-center font-extrabold transition-opacity duration-1000 ${headerInView ? 'opacity-100' : 'opacity-0'}`} ref={headerRef}>
                    <h1>How to buy <span className='text-pink'>$LADY</span></h1>
                </div>

                <div className="grid md:grid-cols-2 gap-10">
                    <div ref={leftGridRef}>
                        <div className={`img-wrap transition-transform duration-1000 ${leftGridInView ? 'translate-x-0 opacity-100' : '-translate-x-full opacity-0'}`} >
                            <img src="/asset/banner 8.png" alt="LAdY" />
                            <img src="/asset/bb.png" alt="LAdY" />
                        </div>
                    </div>

                    <div ref={rightGridRef}>
                        <div className={`steps transition-transform duration-1000 ${rightGridInView ? 'translate-x-0 opacity-100' : 'translate-x-full opacity-0'}`}>
                            {steps.map((i, index) => (
                                <div className="card mb-10 rounded-ss-mdrounded" key={index}>
                                    <div className="flex gap-5">
                                        <div className="wrap">
                                            <div className="sn flex">
                                                <span className='flex p-4 px-6 font-bold text-white bg-grad rounded-full font-candy'>{index + 1}</span>
                                            </div>
                                        </div>
                                        <div className="text-wrap mt-10 bg-white bg-opacity-20 p-4 w-full">
                                            <h1 className='font-bold text-3xl font-candy text-[#f6537f]'>{i.title}</h1>
                                            <span className='text-gray-300'>{i.text}</span>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    );
}
