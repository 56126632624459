import React from 'react'
import { FaLinkedin, FaTelegram, FaTwitter } from 'react-icons/fa'

export default function Footer() {
    return (
        <div className="flex justify-between items-center bg-black px-8 font-pig">
            <span className='font-bold text-white text-lg font-candy'><span className='text-pink'>Lady on Ton Token</span> © 2024</span>
            <div className="wrap flex items-center gap-10">
                <div className="wrap">
                    <img src="/asset/logo.png" alt="Lady" className='w-[50px]' />
                </div>
                <div className="flex justify-center gap-3 items-center my-10">
                    <span className='flex p-2 bg-gray-900 text-pink rounded-full text-2xl'><a href="https://x.com/LadyTonToken" target='_blank'><FaTwitter /></a></span>
                    <span className='flex p-2 bg-gray-900 text-pink rounded-full text-2xl'><a href="https://t.me/LadyonTon" target='_blank'><FaTelegram /></a></span>
                    {/* <span className='flex p-2 bg-gray-900 text-pink rounded-full text-2xl'><FaLinkedin /></span> */}
                </div>
            </div>
        </div>
    )
}
