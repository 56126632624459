import React, { useState } from 'react';
import { useInView } from 'react-intersection-observer';
import Video from './Video';
import Container from './Container';

export default function Origin() {
    const { ref: videoRef, inView: videoInView } = useInView({
        triggerOnce: true,
        threshold: 0.1,
    });

    const { ref: textRef, inView: textInView } = useInView({
        triggerOnce: true,
        threshold: 0.1,
    });

    return (
        <div className="wrap py-16 font-pig" id="origin">
            <Container>
                <div className="grid md:grid-cols-2 flex-wrap-reverse items-center gap-10">
                    <div className={`video-wrap lg:hidden transition-transform duration-1000`}>
                        <Video />
                    </div>
                    <div className={`text-wrap transition-transform duration-1000 ${textInView ? 'animate-fade-in-right' : 'opacity-0'}`} ref={textRef}>
                        <h1 className='text-7xl font-extrabold mb-4 font-candy'>ORIGIN OF <span className='text-pink'>$LADY</span></h1>
                        <span>Lady, also known as <span className=' font-serif'>"</span>Girl,<span className=' font-serif'>"</span> originated from a VK <span className=' font-serif'>(</span>VKontakte<span className=' font-serif'>)</span> mascot competition in <span className="font-candy">2006</span> . Inspiration for Lady was taken from the popular character <span className=' font-serif'>"</span>Vault Boy,<span className=' font-serif'>"</span> also known as <span className=' font-serif'>"</span>Pip-Boy,<span className=' font-serif'>"</span> from the Fallout video game series<span className=' font-serif'>.</span> <br /> <br />

                            Following the competition<span className=' font-serif'>,</span> Lady became a staple on VK alongside <span className=' font-serif'>"</span>Spotty the Dog<span className=' font-serif'>"</span> until <span className="font-candy">2013</span> when Pavel Durov stepped down and founded Telegram<span className=' font-serif'>.</span> <br /> <br />

                            In step with Pavel<span className=' font-serif'>,</span> Lady transitioned primarily to Telegram, where she was accompanied by the creation of <span className=' font-serif'>"</span>Man.<span className=' font-serif'>".</span> <br /> <br />

                            In recent years<span className=' font-serif'>,</span> Telegram<span className=' font-serif'>'</span>s art and media have depicted Lady and Man in various forms<span className=' font-serif'>,</span> including native sticker packs created by Telegram artists that reimagine the mascots<span className=' font-serif'>'</span> characters, such as <span className=' font-serif'>'</span>Such a Lady<span className=' font-serif'>'.</span> <br /> <br />
                            Thread below as well as VK blog36 both mentioning the competition with blog<span className=' font-serif'>35</span> linked <span className=' font-serif'>(</span>link moved or broke<span className=' font-serif'>)</span> <br />

                            <a className='text-blue-500 font-candy text-xl' href="x.com/dacrimeator/status/1758974971270017464" target='_blank' >x.com/dacrimeator/status/1758974971270017464</a> <br />
                            <a className='text-blue-500 font-candy text-xl' href="vk.com/blog/blog36" target='_blank'> vk.com/blog/blog36</a> <br />
                            <span className="font-candy text-xl">Original Girl/Lady artist: @ohuenko</span>
                        </span>
                    </div>
                    <div className={`video-wrap hidden lg:block transition-transform duration-1000 ${videoInView ? 'animate-fade-in-left' : ''}`} ref={videoRef}>
                        <Video />
                    </div>
                </div>
            </Container>
        </div>
    );
}
