import React from 'react';
import { useInView } from 'react-intersection-observer';
import SlantBox from './SlantBox';
import Container from './Container';

export default function Tokenomics() {
    const tax = ["Marketing", "Liquidity", "Dev"];

    const { ref: leftRef, inView: leftInView } = useInView({
        triggerOnce: true,
        threshold: 0.1,
    });

    const { ref: middleRef, inView: middleInView } = useInView({
        triggerOnce: true,
        threshold: 0.1,
    });

    const { ref: rightRef, inView: rightInView } = useInView({
        triggerOnce: true,
        threshold: 0.1,
    });

    return (
        <div className="wrap py-16" id="tokenomics">
            <Container>
                <div className="relative mb-16">
                    <img src="/asset/burn.gif" alt="Lady" className='absolute w-[100px] right-0 top-0' />
                </div>
                <div className="grid md:grid-cols-3 gap-10 items-center">
                    <div className="wrap" ref={leftRef}>
                        <div className={`wrap flex flex-col gap-10 transition-transform duration-1000 ${leftInView ? 'translate-x-0 opacity-100' : '-translate-x-full opacity-0'}`}>
                            <div className="text-wrap">
                                <h1 className='lg:text-6xl text-4xl font-extrabold mb-4 font-candy'>$LADY <span className='text-pink'>TOKENOMICS</span></h1>
                            </div>

                            <div className="taxes">
                                <div className="header font-bold text-2xl text-center mb-4">
                                    <h1>TAXES <span className='font-candy'>(0%)</span></h1>
                                </div>

                                {tax.map((i, index) => (
                                    <SlantBox text1={i} text2={"0%"} key={index} />
                                ))}
                            </div>
                        </div>
                    </div>

                    <div ref={middleRef} className={`img-wrap transition-opacity duration-1000 ${middleInView ? 'opacity-100' : 'opacity-0'}`}>
                        <img src="/asset/intro.png" alt="Intro" />
                    </div>

                    <div className="wrap" ref={rightRef}>
                        <div className={`wrap transition-transform duration-1000 ${rightInView ? 'translate-x-0 opacity-100' : 'translate-x-full opacity-0'}`}>
                            <div className="wrapper md:mt-32">
                                <div className="header font-bold text-2xl text-center mb-4 font-pig">
                                    <h1>ABOUT TOKEN</h1>
                                </div>

                                <SlantBox text1={"Token Name"} text2={"LADY"} />
                                <SlantBox text1={"Token Symbol"} text2={"$LADY"} />
                                <SlantBox text1={"Total Supply"} text2={"100,000,000 "} />


                                <div className="header font-bold text-xl text-center font-pig">
                                    <h1>Contract Address</h1>
                                </div>
                                <div className="break-words bg-black mb-3 flex justify-between items-center py-3 px-6 text-white parallel font-pig">
                                    <span className="font-bold break-all font-candy space-x-1">{"EQASaIVDyf8QBfhiLUOeiu2ayVoPZYadaPQTUAO1uED6TvAA"}</span>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div className="relative mt-32">
                    <img src="/asset/burn.gif" alt="Lady" className='absolute w-[100px] left-0 bottom-0' />
                </div>
            </Container>
        </div>
    );
}
