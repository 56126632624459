import React, { useState } from 'react';
import { useInView } from 'react-intersection-observer';
import Container from './Container';

export default function About() {
    const about = [
        {
            title: "Contract Renounced",
            text: "Lady's making a bold move! He's renounced the contract, setting a new course for unstoppable autonomy!",
            img: "/asset/ren.gif"
        },
        {
            title: "Liquidity Burned",
            text: "Feeling the heat? Lady's fired up and burning through all the liquidity, making waves in the market!",
            img: "/asset/burn.gif"
        },
        {
            title: "Zero Taxes",
            text: "Lady tried to find taxes, but couldn't succeed. Maybe you will have better luck.",
            img: "/asset/tax.gif"
        }
    ];

    const [imageInView, setImageInView] = useState(false);
    const [textInView, setTextInView] = useState(false);
    const [cardsInView, setCardsInView] = useState(Array(about.length).fill(false));

    const { ref: imageRef, inView: imageVisible } = useInView({
        triggerOnce: true,
        threshold: 0.3,
    });

    const { ref: textRef, inView: textVisible } = useInView({
        triggerOnce: true,
        threshold: 0.3,
    });

    const handleCardInView = (index, inView) => {
        if (inView) {
            setCardsInView((prev) => {
                const newState = [...prev];
                newState[index] = true;
                return newState;
            });
        }
    };

    return (
        <div className="wrap abt font-pig" id="about">
            <Container>
                <div className="py-32">
                    <div className="grid md:grid-cols-2 items-center">
                        <div className={`img-wrap flex justify-center ${imageVisible ? 'animate-fade-in-left' : 'opacity-0'}`} ref={imageRef}>
                            <img src="/asset/ll.png" alt="LADY" className='w-[300px]' />
                        </div>
                        <div className={`text-wrap bg-white bg-opacity-10 p-4 py-10 backdrop-blur-md ${textVisible ? 'animate-fade-in-right' : 'opacity-0'}`} ref={textRef}>
                            <h1 className='text-5xl font-extrabold text-white mb-3 font-candy'><span className="text-pink">WHY</span> $LADY</h1>
                            <span className='text-gray-50'><span className=' font-serif'>$</span>LADY represents more than just a cryptocurrency<span className=' font-serif'>-</span>it embodies a community that thrives on meaningful connections<span className=' font-serif'>.</span> Whether you<span className=' font-serif'>'</span>re new to crypto or a seasoned investor<span className=' font-serif'>,</span> <span className=' font-serif'>$</span>LADY invites you to join a community<span className=' font-serif'>-</span>driven initiative that promises an exciting journey ahead<span className=' font-serif'>.</span>
                            </span>
                        </div>
                    </div>

                    <div className="grid lg:grid-cols-3 gap-12 mt-32">
                        {about.map((item, index) => (
                            <div
                                key={index}
                                className={`wrap text-center rounded-3xl p-3 bg-black bg-opacity-55 backdrop-blur-xl border border-pink-800 border-opacity-50 ${cardsInView[index] ? 'animate-fade-in-bottom' : ''}`}
                            >
                                <div className="img-wrap mb-5">
                                    <img src={item.img} alt="Locked Emoji" className=' w-[100px] mx-auto' />
                                </div>
                                <div className="text-wrap">
                                    <h1 className='text-2xl font-bold text-pink mb-3'>{item.title}</h1>
                                    {/* <span className='text-lg text-gray-300'>{item.text}</span> */}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </Container>
        </div>
    );
}
