import React from 'react';
import { useInView } from 'react-intersection-observer';
import { FaLinkedin, FaTelegram, FaTwitter } from 'react-icons/fa';
import Container from './Container';

export default function Socials() {
    const { ref, inView } = useInView({
        triggerOnce: true,
        threshold: 0.1,
    });

    return (
        <div className="socials font-pig">
            <Container>
                <div ref={ref} className={`grid md:grid-cols-2 gap-10 items-center transition-opacity duration-1000 ${inView ? 'opacity-100' : 'opacity-0'}`}>
                    <div className="wrap">
                        <img src="/asset/banner-half.png" alt="lady" className='w-[400px]' />
                    </div>
                    <div className="text-wrap text-center">
                        <h1 className='md:text-6xl text-4xl font-extrabold '>FOLLOW ON <span className='text-pink font-candy'>SOCIAL MEDIA</span></h1>
                        <div className="flex justify-center gap-3 items-center my-10">
                            <span className='flex p-4 bg-gray-950 text-pink rounded-full text-5xl'><a href="https://x.com/LadyTonToken" target='_blank'><FaTwitter /></a></span>
                            <span className='flex p-4 bg-gray-950 text-pink rounded-full text-5xl'><a href="https://t.me/LadyonTon" target='_blank'><FaTelegram /></a></span>
                            {/* <span className='flex p-4 bg-gray-950 text-pink rounded-full text-5xl'><FaLinkedin /></span> */}
                        </div>
                        {/* <span>Stay tuned to our social media and join the community space. Together, we will push this token to the moon!</span> */}
                    </div>
                </div>
            </Container>
        </div>
    );
}
