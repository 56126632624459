import React from 'react'
import Intro from './Components/Intro'
import About from './Components/About'
import Htb from './Components/Htb'
import Header from './Components/Header'
import Origin from './Components/Origin'
import Tokenomics from './Components/Tokenomics'
import Socials from './Components/Socials'
import Footer from './Components/Footer'

export default function Home() {
    return (
        <>
            <div className="wrap overflow-clip">
                <Header />
                <Intro />
                <About />
                <Origin />
                <Htb />
                <Tokenomics />
                <Socials />
                <Footer />
            </div>
        </>
    )
}
