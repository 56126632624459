import React, { useRef } from "react";

export default function Video() {
    const videoRef = useRef(null);

    const handleVideoEnded = () => {
        if (videoRef.current) {
            videoRef.current.currentTime = 0;
            videoRef.current.play();
        }
    };

    return (
        <div className="video">
            {/* Video section */}
            <div
                className="w-full flex justify-center items-center my-4"
                style={{ opacity: 1 }}
            >
                <video
                    ref={videoRef}
                    autoPlay
                    className="rounded w-100"
                    controls
                    onEnded={handleVideoEnded}
                >
                    <source src="/asset/girl_lady.mp4" type="video/mp4" />
                </video>
            </div>
        </div>
    );
}
